import React from "react";
import Layout from "../../components/layout/layout";
import "../../styles/styles.scss";

export default () => {
  return (
    <Layout pageTitle="Payment Canceled">
      <div style={{ height: "10vh" }}></div>
      <div style={{ height: "10vh" }}></div>

      <div className="container">
        <div className="columns">
          <div className="column"></div>

          <div className="column is-10">
            <div className="box">
              <h1 className="title">Payment Canceled</h1>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
      <div style={{ height: "10vh" }}></div>
      <div style={{ height: "10vh" }}></div>
    </Layout>
  );
};
